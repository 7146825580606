import { Card, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';

const data = [
    {
        img: "https://i.imgur.com/38NAgks.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=kwBuI0i2tTk"
    },
    {
        img: "https://i.imgur.com/YQQ47Uo.jpg",
        youtubeLink: "https://youtu.be/0qeA31eG5_I"
    },
    {
        img: "https://i.imgur.com/IafDeGD.jpg",
        youtubeLink: "https://www.youtube.com/watch?v=aIH-0b_hbRg"
    },
    {
        img: "https://i.imgur.com/t7k2C4z.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=vD2QAEFJo3g"
    },
    {
        img: "https://i.imgur.com/xEwzIyU.jpeg",
        youtubeLink: "https://youtu.be/kRXMYrJRgR8"
    },
    {
        img: "https://i.imgur.com/5nnkZR1.jpeg",
        youtubeLink: "https://youtu.be/-NvJs6xWsW0"
    },
    {
        img: "https://i.imgur.com/l6Wy6tp.jpg",
        youtubeLink: "https://youtu.be/YRLD72PcbYk"
    },
    {
        img: "https://i.imgur.com/kWnpZiy.jpg",
        youtubeLink: "https://youtu.be/ha5YI9QnnRA"
    },
    {
        img: "https://i.imgur.com/7iyBKc4.jpg",
        youtubeLink: "https://www.youtube.com/watch?v=pgcpGmYfHDg"
    },
    {
        img: "https://i.imgur.com/JSaXrjm.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=BcSy1ibh83Y"
    },
    {
        img: "https://i.imgur.com/unSIz5n.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=lEyXVwHbJCI"
    },
    {
        img: "https://i.imgur.com/9qPKfYQ.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=9sbQbPku6Nw"
    },
    {
        img: "https://i.imgur.com/mVTlQ8l.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=W87V_bfLmQs"
    },
    {
        img: "https://i.imgur.com/yKJdG8R.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=suQU8UWz1Zc"
    },
    {
        img: "https://i.imgur.com/dBmIeXY.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=fNBTGb8XIRQ"
    },
    {
        img: "https://i.imgur.com/Lj7xTwG.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=kMGfaXM4SfM"
    },
    {
        img: "https://i.imgur.com/16AWRjg.jpg",
        youtubeLink: "https://www.youtube.com/watch?v=O7ZFGx4zJyQ"
    },
    {
        img: "https://i.imgur.com/81XInyj.jpeg",
        youtubeLink: "https://www.youtube.com/watch?v=tBFqt-bywcw"
    },
    {
        img: "https://i.imgur.com/q0hJu9y.jpg",
        youtubeLink: "https://www.youtube.com/watch?v=l_HmuOulq8Q"
    },
    {
        img: "https://i.imgur.com/4CeE0xF.jpg",
        youtubeLink: "https://www.youtube.com/watch?v=m27HpsqQzos"
    },
    {
        img: "https://i.imgur.com/duLUXeX.jpg",
        youtubeLink: "https://www.youtube.com/watch?v=lGRSOdp-eHM"
    },
    {
        img: "https://i.imgur.com/3XANOnK.jpg",
        youtubeLink: "https://www.youtube.com/watch?v=NW41EoKkUBY"
    },
    {
        img: "https://i.imgur.com/zLTqJ6c.jpg",
        youtubeLink: "https://www.youtube.com/watch?v=q4_IRum84tQ"
    },
    {
        img: "https://i.imgur.com/guAdSaF.jpg",
        youtubeLink: "https://youtu.be/o5WRc73J120"
    },
    {
        img: "https://i.imgur.com/sC8hGNM.jpeg",
        youtubeLink: "https://youtu.be/m73_0lZ5veY"
    },
    {
        img: "https://i.imgur.com/TstZFjJ.jpeg",
        youtubeLink: "https://youtu.be/AopZg_375g4"
    }
]

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        textAlign: "center",
        padding: "20px 10%",
    },
    gridWrapper: {

    },
    grid: {

    },
    header: {
        marginBottom: "10px"
    },
    cardHeader: {
        paddingTop: "50px",
        backgroundImage: "url(./assets/background.png)"
    },
    headerText: {
        margin: "30px 0 10px 0",
        fontFamily: "Caramello",
        fontSize: "50pt",
        color: "white"
    },
    headerMedia: {
        height: "500px",
        backgroundImage: "url(https://i.imgur.com/h71HJ1M.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center 15%",
    },
    card: {
        margin: "10px",
        width: 500,
        height: 400,
    },
    img: {
        display: "flex",
        justifyContent: "center",
    }
}));

function App() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.headerWrapper}>
                <Typography className={classes.headerText} variant="h2">Karen & Martin</Typography>
                <Card className={classes.header} raised={true}>
                    <CardMedia>
                        <div className={classes.headerMedia}></div>
                    </CardMedia>
                </Card>
            </div>
            <div className={classes.gridWrapper}>
                <Grid container spacing={3}>
                    <Grid item cs={3}>
                        <Grid container className={classes.grid} justify="center">
                            {
                                data.map((entry) => (
                                    <Card className={classes.card} raised={true} children={
                                        <a target="_blank" rel="noreferrer" href={entry.youtubeLink}>
                                            <div className={classes.img}>
                                                <img alt="" src={entry.img} style={{
                                                    widht: 500,
                                                    height: 500,
                                                    objectFit: "cover"
                                                }} />
                                            </div>
                                        </a>
                                    } />
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default App;
